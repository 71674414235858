<template>
  <div class="req-temp-container">

    <br />

    <vs-card class="mt-6">
      <div slot="header">
        <h3 class="font-normal">Link to portal</h3>
      </div>
      <vs-row>
          <div class="flex items-center">
            <vs-input class="w-full token mr-10" v-model="portalLink" disabled/>
            <span @click="copyPortalLinkToClipboard">
              <vs-tooltip text="Copy to clipboard" class="pointer">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </vs-tooltip>
            </span>
          </div>
      </vs-row>
    </vs-card>
  </div>
</template>

<script>

export default {
  data() {
    return {
      merchantId: "",
      serverUrl: process.env.VUE_APP_API_URL,
      customerPortalUrl: process.env.VUE_APP_CUSTOMER_PORTAL_URL,
      portalLink: "",
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  mounted() {
    this.merchantId =
      JSON.parse(localStorage.user).userType == "admin"
        ? JSON.parse(localStorage.user).merchantId
        : JSON.parse(localStorage.user).partner.merchantId;
    this.getPartnerId();
    this.getPortalLink();
  },
  methods: {
    getPartnerId() {
      this.partnerId = JSON.parse(localStorage.getItem("user"))._id;
    },
    copyPortalLinkToClipboard() {
      navigator.clipboard.writeText(this.portalLink).then(() => {
        this.$vs.notify({
          title: "Copy to Clipboard",
          text: "Copied text to clipboard",
          color: "success",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-check-circle",
        });
      });
    },
    getPortalLink() {
      this.portalLink =
        this.customerPortalUrl + this.partnerId + "/customer-login";
    },
  },
};
</script>